<template>
  <div class="checkout-done">
    <main-nav></main-nav>
    <v-content>
      <v-container>

        <div class="pos-rel text-xs-center pt-2 pb-5">
          <h2>Your gifts have been created!</h2>
          <div class="line-bottom"></div>
        </div>

        <div class="pos-rel text-xs-center pt-2 pb-3">
          <h1>Your Gift Links</h1>
          <h3 class="caption">We encourage copying them for future reference</h3>
        </div>

        <checkout-item v-for="(item, i) in items" :item="item.gift" :id="item.key" :key="i">
        </checkout-item>

        <div class="text-xs-center pt-1 pb-5">
          <h5 class="blue--text text--darken-2">Please do not redeem any gift cards which you intend to give as a gift.</h5>
        </div>

        <!--
        <div class="pos-rel text-xs-center">
          <div class="line-bottom"></div>
        </div>
        <div class="pos-rel text-xs-center pb-3">
        </div>
        -->

        <div v-if="email && credits>0 && !loggedIn"
          class="grey lighten-2 text-xs-center pt-2 pb-2 px-2"
          style="max-width:500px; margin:auto; border-radius:10px;"
        >

          <div class="white pa-1" style="border-radius:8px;">
            Sign up or log in with <span class="blue--text subheading">{{email}}</span>
            and claim <span class="blue--text subheading">{{credits}}</span> gift credits!
            <br />
            <span class="caption grey--text">99 gift credits is a free premium gift wrap.</span>
          </div>

          <div class="pt-2">
              <v-btn large color="info" to="/signup">Sign up</v-btn>
          </div>

        </div>


        <div class="text-xs-center pt-5">
          <h3 class="pink--text text--darken-2">Thank you for using Swipewrap!</h3>
        </div>

      </v-container>
    </v-content>
  </div>
</template>

<script>
import MainNav from '@/components/navs/MainNav.vue'

import CheckoutItem from '@/components/checkout/CheckoutItem.vue'

export default {
  name:'about',
  components: {
    MainNav,
    CheckoutItem,
  },
  data() {
    return {}
  },
  computed: {

    loggedIn() { return this.$store.getters.user && this.$store.getters.user.emailVerified; },

    credits() { return this.$store.getters.checkoutCreditsBack; },
    email() { return this.$store.getters.checkoutCreditsBackEmail; },
    
    items() {
      return this.$store.getters.checkoutItems;
    },

  },
  methods: {
  },
}
</script>
